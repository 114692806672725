<template>
  <v-expansion-panel-content class="pt-2">
    <p class="mt-4 mb-4">
      <b>❗️Согласованный {{ statuses.agreed.isUnscheduled ? 'внеплановый' : 'плановый' }} сводный статус по проекту: «{{ activeProject.name }}»</b>
      <br>
      За период: {{ week.period }}
    </p>
    <v-textarea
      :value="statuses.agreed.consolidated_text"
      :readonly="true"
      :auto-grow="true"
      solo
      flat
      hide-details
    />

    <p class="mt-4 mb-4">
      <b>❗️Автоматически собранный {{ statuses.formed.isUnscheduled ? 'внеплановый' : 'плановый' }} сводный статус по проекту: «{{ activeProject.name }}»</b>
      <br>
      За период: {{ week.period }}
    </p>
    <v-textarea
      :value="statuses.formed.consolidated_text"
      :readonly="true"
      :auto-grow="true"
      solo
      flat
      hide-details
    />

    <p class="mt-4 mb-4">
      <b>❗️Сводный статус в разрезе каждого из сотрудников по проекту «{{ activeProject.name }}»</b>
      <br>
      За период: {{ week.period }}
    </p>
    <v-textarea
      :value="getAnswers(statuses.formed, activeProject.users)"
      :readonly="true"
      :auto-grow="true"
      solo
      flat
      hide-details
    />

    <div
      v-for="(editStatus, esi) in statuses.edited"
      :key="'edit-status-' + esi"
    >
      <p class="mt-4 mb-4">
        <b>❗️Отредактированный администратором Согласованный {{ statuses.agreed.isUnscheduled ? 'внеплановый' : 'плановый' }} сводный статус по проекту: «{{ activeProject.name }}»</b>
        <br>
        За период: {{ week.period }}
      </p>
      <v-textarea
        :value="editStatus.consolidated_text"
        :readonly="true"
        :auto-grow="true"
        solo
        flat
        hide-details
      />
    </div>
  </v-expansion-panel-content>
</template>

<script>
  import AnswerService from '../services/AnswerService'
  export default {
    name: 'ConsolidateStatusCard',

    props: {
      week: {
        type: Object,
        default: null,
      },
      statuses: {
        type: Object,
        default: () => [],
      },
      activeProject: {
        type: Object,
        default: null,
      },
    },

    methods: {
      getAnswers (status, users) {
        let answerText = ''

        for (const answer of status.answers) {
          const user = users.find((user) => user.user_id === answer.user_id)
          if (user) {
            answerText += AnswerService.getUserAnswer(answer, user)
          }
        }

        return answerText
      },
    },
  }
</script>
