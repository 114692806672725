import { render, staticRenderFns } from "./ConsolidateStatusCard.vue?vue&type=template&id=51f8a0cf&"
import script from "./ConsolidateStatusCard.vue?vue&type=script&lang=js&"
export * from "./ConsolidateStatusCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VExpansionPanelContent,VTextarea})
